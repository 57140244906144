import { Result } from "../result";
import { TimeUnit } from "./time-unit";

export type Meridiem = "am" | "pm";

export class TimeWithMeridiem {
  readonly time: TimeUnit;
  readonly mr: Option<Meridiem>;

  constructor(time: TimeUnit, mr: Option<Meridiem>) {
    this.time = time;
    this.mr = mr;
  }

  resolve(defaultMr: Meridiem = "am"): Option<{
    time: TimeUnit;
    displayHrs: number;
    merdiemPresent: boolean;
  }> {
    const mr = this.mr ?? defaultMr;

    let time = this.time;
    let hrs = this.time.hrs;
    if (hrs == 12 && mr == "am") {
      hrs = 0;
      time = time.add({ hrs: -12 });
    } else if (hrs == 12 && mr == "pm") {
      hrs = 12;
    } else if (mr == "pm") {
      hrs += 12;
      time = time.add({ hrs: 12 });
    }

    if (time.days >= 1 || time.sign == -1) {
      return null;
    }

    return {
      time,
      displayHrs: hrs,
      merdiemPresent: this.mr != null,
    };
  }
}

export namespace TimeWithMeridiem {
  const MERIDIEM_REGEX =
    /^([0-1]?[0-9]|2[0-3]):?([0-5][0-9])?\s*(am|pm|AM|PM)?/;

  export function parse(text: string): Option<TimeWithMeridiem> {
    const matches = text.match(MERIDIEM_REGEX);
    if (!matches) {
      return null;
    }

    const [hrs, mins, mrr] = matches;
    let time = TimeUnit.fromHmsStr(hrs, mins);
    if (Result.isErr(time)) {
      return null;
    }
    time = Result.unwrap(time);

    const mr: Option<Meridiem> = mrr ? (mrr.toLowerCase() as Meridiem) : null;
    return new TimeWithMeridiem(time, mr);
  }
}
