import { css } from "../../component-styles";
import { GESTURE_MANAGER } from "../../gesture-manager";

export namespace Button {
  export const make = () => document.createElement("button");

  export function makeWith(
    $content: HTMLElement,
    props: Optional<{
      style: "flat" | "primary" | "secondary" | "destructive";
      onClick: EmptyFunction;
    }>
  ): HTMLButtonElement {
    const $button = Button.make();
    $button.appendChild($content);
    props.style && $button.toggleAttribute(props.style, true);
    props.onClick &&
      GESTURE_MANAGER.addPointerEvent($button, {
        onClick: props.onClick,
      });
    return $button;
  }

  export const style = /* css */ `
    .button,
    button {
      --font-size: var(--p-size);

      border: none;
      background: none;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      user-select: none;
      text-decoration: none;
      transition: background-color 0.15s ease;

      white-space: nowrap;
      text-overflow: ellipsis;

      padding: 8px 12px;
    }
    button:focus {
      outline: none;
    }
    a.button:visited {
      color: var(--primary-text-color);
    }
    .button:hover,
    button:hover {
      background-color: var(--hover-color);
    }
    button[selected] {
      background-color: var(--selected-color);
    }

    button[primary],
    .button[primary] {
      background-color: var(--primary-button-background-color);
      color: var(--inverse-text-color);
    }

    button[primary]:hover,
    .button[primary]:hover {
      background-color: var(--color);
    }

    button[secondary],
    .button[secondary] {
      background-color: var(--secondary-button-background-color);
      color: var(--secondary-text-color);
    }

    button[secondary]:hover,
    .button[secondary]:hover {
      background-color: var(--selected-color);
    }

    button[flat],
    .button[flat] {
      color: var(--tertiary-text-color);
      text-decoration: none;
    }

    button[flat]:hover,
    .button[flat]:hover {
      background-color: var(--hover-color);
    }

    button[destructive],
    .button[destructive] {
      background: var(--red);
      color: var(--inverse-text-color);
    }`;

  export const $style = css(style);
}
