import { Cookies } from "./cookies";
import { DevMode } from "./dev-mode";
import { dev } from "./log";

export class DevTools {
  static devModeIdentifier = "dev-mode";

  static get queryParam(): string {
    return `dev-mode=${DevTools.getMode()}`;
  }

  static setMode(mode: DevMode, domain?: Option<string>) {
    Cookies.set(DevTools.devModeIdentifier, {
      value: mode,
      domain,
    });
    DevTools.emitUpdate();
    location.reload();
  }

  static getMode(): DevMode {
    return (Cookies.get(DevTools.devModeIdentifier) as DevMode) ?? "prod";
  }

  static emitUpdate() {
    const mode = DevTools.getMode();
    const meta =
      (document.getElementById("dev-mode") as HTMLMetaElement) ??
      document.createElement("meta");
    meta.id = "dev-mode";
    meta.name = "dev-mode";
    meta.content = mode;
    document.head.appendChild(meta);

    window.dispatchEvent(
      new CustomEvent("devModeChanged", {
        detail: {
          mode,
        },
      })
    );
  }
}

DevTools.emitUpdate();

// @ts-ignore
window.addEventListener("setDevMode", (e: CustomEvent) => {
  dev(`[DevTools] setting mode to "${e.detail}"`);
  const mode: DevMode = e.detail;
  const rootDomain = window.location.host.split(".").slice(-2).join(".");
  DevTools.setMode(mode, rootDomain);
});

window["admin"] = DevTools;
