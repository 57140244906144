import { DateTime, LogicalTimezone, Time, TimeUnit } from "./mod";
import { naivedate, NaiveDate, YearMonthDay } from "./naive-date";
import { NaiveTime } from "./naive-time";
import { DaysSinceEpoch, Month1, MsSinceEpoch } from "./units/units";

export class NaiveDateTime {
  readonly ymd: NaiveDate;
  readonly timeOfDay: NaiveTime;

  constructor(ymd: NaiveDate, timeOfDay: NaiveTime = NaiveTime.ZERO) {
    this.ymd = ymd;
    this.timeOfDay = timeOfDay;
  }

  static fromMse(mse: MsSinceEpoch): NaiveDateTime {
    const { div, rem } = NaiveTime.divByDay(mse);
    return new NaiveDateTime(
      NaiveDate.fromDse(div as DaysSinceEpoch),
      new NaiveTime(rem)
    );
  }

  add(delta: TimeUnit): NaiveDateTime {
    const added = this.timeOfDay.add(delta);
    if (added.days == 0) {
      return new NaiveDateTime(this.ymd, added);
    }
    const rem = added.sub({ days: added.days });
    return new NaiveDateTime(
      this.ymd.add({ days: added.days }).castValid(),
      rem
    );
  }

  sub(delta: TimeUnit): NaiveDateTime {
    return this.add(new TimeUnit(delta.asMs * -1));
  }

  get mse(): MsSinceEpoch {
    return (this.ymd.daysSinceEpoch * Time.MS_PER_DAY +
      this.timeOfDay.asMs) as MsSinceEpoch;
  }

  get dse(): DaysSinceEpoch {
    return this.ymd.daysSinceEpoch;
  }

  asTz<Tz extends string>(offset: LogicalTimezone<Tz>): DateTime<Tz> {
    return new DateTime(this, offset);
  }
}

export function naivedatetime(
  year: number = 0,
  mth1: number = 1,
  day1: number = 1,
  hrs: number = 0,
  mins: number = 0,
  secs: number = 0
): NaiveDateTime {
  return new NaiveDateTime(
    naivedate(year, mth1, day1),
    NaiveTime.fromHms({
      hrs,
      mins,
      secs,
    })
  );
}
