import { Point } from "./point";
import { replaceAll } from "./polyfill";

export namespace Utils {
  export const isNotUndefined = <T>(item: Option<T>): item is T => {
    return !!item;
  };

  export function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  export function uuidToDomId(prefix: string, uuid: Uuid): string {
    return prefix + "_" + replaceAll(uuid, "-", "");
  }

  export function debounce<T>(
    returnFunc: (...args: any[]) => T | Promise<T>,
    waitMs: number
  ): (...args: any[]) => Promise<T> {
    let timeout: number;
    return function (...args: any[]): Promise<T> {
      clearTimeout(timeout);
      return new Promise((resolve) => {
        timeout = setTimeout(() => {
          // @ts-ignore
          resolve(returnFunc.apply<any, any[], T | Promise<T>>(this, args));
        }, waitMs);
      });
    };
  }

  export function memoize<A, R>(fn: (a: A) => R): (a: A) => R {
    const cache: Map<A, R> = new Map();
    return (...args: any[]) => {
      const arg = args[0] as A;
      const maybeRes: Option<R> = cache.get(arg);
      if (maybeRes) {
        return maybeRes;
      }
      const result = fn(arg);
      cache.set(arg, result);
      return result;
    };
  }

  export function getOrDefault(
    s: Option<string>,
    defaultValue: string
  ): string {
    if (s == null || s.length == 0) return defaultValue;
    return s;
  }

  export function passesJitterThreshold(
    p: Point,
    threshold: number = 3
  ): boolean {
    return Math.abs(p.x) >= threshold || Math.abs(p.y) >= threshold;
  }
}
