export type LiveDataWriteable<T> = {
  update(t: T): void;
};

export type LiveDataReadable<T> = {
  get(): T;
  addListener(target: any, cb: (t: T) => void): void;
  removeListener(target: any): void;
};

export class LiveData<T> implements LiveDataReadable<T>, LiveDataWriteable<T> {
  private inner: T;
  private listeners: Map<any, (t: T) => void> = new Map();

  constructor(t: T) {
    this.inner = t;
  }

  static new<T>(t: T): LiveData<T> {
    return new LiveData(t);
  }

  updateSilently(t: T) {
    this.inner = t;
  }

  update(t: T) {
    this.inner = t;
    for (const cb of this.listeners.values()) {
      cb(this.inner);
    }
  }

  get(): T {
    return this.inner;
  }

  addListener(target: any, cb: (t: T) => void) {
    this.listeners.set(target, cb);
  }

  removeListener(target: any) {
    this.listeners.delete(target);
  }

  addListenerAndTrigger(target: any, cb: (t: T) => void) {
    this.listeners.set(target, cb);
    cb(this.inner);
  }
}
