import { nonnull } from "./assert";

export namespace Rational {
  export function first(): Rational {
    return {
      n: 1,
      d: 1,
    };
  }

  export function nth(n: number): Rational {
    return {
      n: 1,
      d: n + 1,
    };
  }

  export function next(curr: Rational): Rational {
    return {
      n: curr.n,
      d: curr.d + 1,
    };
  }

  export function midpoint(upper: Option<Rational>, lower: Option<Rational>) {
    if (!upper) {
      lower = nonnull(lower);
      return { n: lower.n + 1, d: lower.d };
    }
    if (!lower) {
      upper = nonnull(upper);
      return { n: upper.n, d: upper.d + 1 };
    }
    return { n: upper.n + lower.n, d: upper.d + lower.d };
  }

  export function toString(r: Rational): string {
    return `${r.n}/${r.d}`;
  }

  export function fromString(s: string): Rational {
    const [n, d] = s.split("/");
    return {
      n: parseInt(n),
      d: parseInt(d),
    };
  }
}
