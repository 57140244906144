import "@lona/dev-tools";
import "@lona/components/sidebar-expand-button";
import "@lona/components/button-group-row";
import "../lib/study-card-carousel";
import "../lib/sidebar-content";
import "../lib/command-row";
import "../lib/actions-bar";
import "../lib/vocab-grid";

import { Hotkeys } from "@lona/hotkeys";
import { $ } from "@lona/dom-selectors";
import { SidebarExpandButton } from "@lona/components/sidebar-expand-button";
import { DomUtils } from "@lona/dom";
import { ButtonGroupRow } from "@lona/components/button-group-row";
import { LonaIcons } from "@lona/ui/icons";
import { LiveData } from "@lona/livedata";
import { ActionsBar } from "../lib/actions-bar";
import { VocabGrid } from "../lib/vocab-grid";
import { SimpleKeyframe } from "@lona/keyframe";
import { StudyCard } from "../lib/study-card";
import { SidebarContent } from "../lib/sidebar-content";
import { Toast } from "@lona/components/toast/toast";
import { EXAMPLE } from "../lib/examples";
import { GESTURE_MANAGER } from "@lona/gesture-manager";
import { dev } from "@lona/log";
import { StudyCardCarousel } from "../lib/study-card-carousel";
import { uuidv4 } from "@lona/crypto";
import { LangiloApi } from "../lib/langilo-api";
import { Network } from "../lib/@generated/models";
import { StudyCardPreview } from "../lib/study-card-preview";

const $sidebarButton = $<SidebarExpandButton>("sidebar-button");
const $tabSelector = $<ButtonGroupRow<App.Tabs>>("tab-selector");
const $actionsBar = $<ActionsBar>("actions-bar");
export const $vocabGrid = $<VocabGrid>("vocab-grid");
const $sidebarContent = $<SidebarContent>("sidebar-content");
const $studyCardCarousel = $<StudyCardCarousel>("study-card-carousel");

/* TODO: this takes a while so queue it asap */
let cardPool = LangiloApi.post("/api/new-card");

namespace Matrix {
  export function setValue<T>(
    mat: T[][],
    v: T,
    coord: { c: number; r: number }
  ) {
    const yy = mat[coord.r];
    if (!yy) {
      mat[coord.r] = [];
    }
    mat[coord.r][coord.c] = v;
  }
}

export class AllCardList {
  readonly allCards: StudyCard.Prop[];
  readonly cardMap: StudyCard.Prop[][];

  constructor(cardList: StudyCard.Prop[], map: StudyCard.Prop[][]) {
    this.allCards = cardList;
    this.cardMap = map;
  }

  static withFlatList(cards: StudyCard.Prop[]): AllCardList {
    const map: StudyCard.Prop[][] = [];

    for (const prop of cards) {
      Matrix.setValue(map, prop, {
        c: prop.coord.c,
        r: prop.coord.r,
      });
    }

    return new AllCardList(cards, map);
  }

  // INTO semantics (from RUST) ->
  // the old cardlist has been moved and old references should be discarded
  //
  // NOTE: probably safer to deep clone everything but more expensive
  //
  intoWithNewCard(card: StudyCard.Prop): AllCardList {
    Matrix.setValue(this.cardMap, card, {
      c: card.coord.c,
      r: card.coord.r,
    });

    const allCards = this.allCards;
    allCards.push(card);

    const newCardList = new AllCardList(allCards, this.cardMap);
    return newCardList;
  }

  getProp(coord: { row: number; col: number }): Option<StudyCard.Prop> {
    const row = this.cardMap[coord.row];
    if (!row) return null;
    return row[coord.col];
  }
}

export namespace State {
  export const cardList = new LiveData(AllCardList.withFlatList(EXAMPLE));
  export const newCardsToSelect = new LiveData(3);
  export const cardsToStudy: LiveData<StudyCard.Prop[]> = new LiveData(EXAMPLE);
  export const cardStudyState: Map<Uuid, { didStudy: boolean }> = new Map();
}

export namespace Actions {
  export function toggleSidebar() {
    const revealed = $sidebarButton.toggleAttribute("revealed");
    DomUtils.flipAnimation(
      [
        $("main-content-bg"),
        $("logo-info"),
        $("study-card-carousel"),
        $("study-card-action-row"),
        $("command-row"),
        $vocabGrid,
        // $vocabGrid.$("viewport"),
        $actionsBar,
      ],
      () => {
        $("main-content").toggleAttribute("sidebar-expanded", revealed);
      },
      {
        shouldMeasure: true,
      }
    );
  }

  export async function markAsReviewed(prop: StudyCard.Prop) {
    // State.cardStudyState.set(prop.id, { didStudy: true });

    let cards = State.cardsToStudy.get();
    const newCards = cards.filter((card) => card.id != prop.id);
    State.cardsToStudy.update(newCards);
    // .cards!.update(newCards);
  }

  let vocabToUse: Option<Network.VocabList>;
  export async function createNewCard(coord: { r: number; c: number }) {
    const cardsAvailable = State.newCardsToSelect.get();
    if (cardsAvailable == 0) return;

    const vocabs = await cardPool;
    vocabToUse = vocabs;
    let newVocab = vocabToUse.vocab.pop();
    while (newVocab && newVocab.word.length > 2) {
      newVocab = vocabToUse.vocab.pop();
    }

    if (!newVocab) {
      Toast.error("Something went wrong. Please come back later!", {
        // todo: add preview
      });
      return;
    }

    const newCard: StudyCard.Prop = {
      id: uuidv4(),
      title: newVocab.word,
      task: "Define the word",
      definition: newVocab.definition,
      pronunciation: "",
      background: "#db9ca0",
      coord,
    };

    // let newVocab = EXAMPLE[0];
    // const newCard: StudyCard.Prop = {
    //   id: uuidv4(),
    //   title: newVocab.title,
    //   task: "Define the word",
    //   definition: newVocab.definition,
    //   pronunciation: "",
    //   background: "#db9ca0",
    //   coord,
    // };

    Toast.info("You've added a new card!", {
      description: StudyCardPreview.makeWith(newCard),
    });

    State.newCardsToSelect.update(cardsAvailable - 1);
    State.cardList.update(State.cardList.get().intoWithNewCard(newCard));
  }

  export function navigate(tab: App.Tabs) {
    // dev("navigating");
    // $tabSelector.onSelected(tab);
    $tabSelector.bindSelected(tab);
  }
}

$sidebarButton.onClick = () => Actions.toggleSidebar();

Hotkeys.init([["s", Actions.toggleSidebar]]);

//
// 1) Tab selector
//
$tabSelector.bind("quiz", [
  {
    label: LonaIcons.$svg("journal", {
      margin: "0px 4px",
    }),
    identifier: "quiz",
  },
  {
    label: LonaIcons.$svg("grid", {
      margin: "0px 4px",
    }),
    identifier: "grid",
  },
]);
$tabSelector.onSelected = (t) => {
  if (t == "quiz") {
    $("current-tab").textContent = "Quiz";
    $vocabGrid.animate(SimpleKeyframe.builder().slideOut().fadeOut().build(), {
      duration: 300,
    }).onfinish = () => {
      $vocabGrid.toggleAttribute("hide", true);
    };
  } else {
    $("current-tab").textContent = "Vocab";
    $vocabGrid.toggleAttribute("hide", false);
    $vocabGrid.animate(SimpleKeyframe.builder().slideIn().fadeIn().build(), {
      duration: 300,
    }).onfinish = () => {};
    $actionsBar.toggleAttribute("prompt-has-new-cards", false);
  }
};

//
// 2) Grid tab
//
$sidebarContent.bindLive(State.cardList);
$vocabGrid.bindLive(State.cardList);

//
// 3) New card buttons
//
GESTURE_MANAGER.addPointerEvent($("generate-next-btn"), {
  onClick: () => Actions.navigate("grid"),
});

State.newCardsToSelect.addListenerAndTrigger(
  $actionsBar,
  $actionsBar.bindPromptHasNewCards.bind($actionsBar)
);

// [1] Add more cards
const bindCardsAvailable = (n: number) => {
  if (n == 0) {
    $("generate-next-btn").style.display = "none";
  } else {
    $("generate-next-btn").style.display = "block";
  }
  $cardsAvailable.textContent = String(n);
};
const $cardsAvailable = $("cards-available");
State.newCardsToSelect.addListenerAndTrigger(
  $cardsAvailable,
  bindCardsAvailable
);
State.cardsToStudy.addListenerAndTrigger(
  $("remaining-cards"),
  (n) => ($("remaining-cards").textContent = `${3 - n.length} / ${3}`)
);

//
// 4) bind carousel
//
$studyCardCarousel.bind(State.cardsToStudy);

export namespace App {
  export type Tabs = "quiz" | "grid";
}
