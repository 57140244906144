import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";

@component({
  name: "std-card",
})
export class Card extends LonaWebComponent {
  private timeout: Option<number>;

  flip() {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    const $card = this.$("root");
    if ($card.classList.contains("card--flipped")) {
      $card.classList.add("card--unflip");
      this.timeout = setTimeout(function () {
        $card.classList.remove("card--flipped", "card--unflip");
      }, 500);
    } else {
      $card.classList.add("card--flipped");
    }
  }

  static $styles: HTMLTemplateElement[] = [
    css`
      :host {
        display: block;
        height: 440px;
        width: 280px;
      }

      #root {
        position: relative;
        transform-style: preserve-3d;
        transform: rotateY(180deg);
        border-radius: 20px;
        box-shadow: var(--box-shadow);
        background: var(--background, #adb88a);
        height: 100%;
        width: 100%;
      }

      .card-face {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .card-back {
        overflow: hidden;
        border-radius: 20px;
      }

      .card-front {
        overflow: hidden;
        border-radius: 20px;
        transform: rotateY(180deg);
      }
    `,
    css`
      .card--flipped {
        animation: cardFlip 0.275s forwards linear;
      }

      .card--unflip {
        animation: cardUnFlip 0.275s forwards linear;
      }

      @keyframes cardFlip {
        0% {
          transform: rotateZ(0deg) rotateY(180deg);
        }
        50% {
          transform: rotateZ(-10deg) rotateY(90deg);
        }
        100% {
          transform: rotateZ(0deg) rotateY(0deg);
        }
      }

      @keyframes cardUnFlip {
        0% {
          transform: rotateZ(0deg) rotateY(0deg);
        }
        50% {
          transform: rotateZ(-10deg) rotateY(90deg);
        }
        100% {
          transform: rotateZ(0deg) rotateY(180deg);
        }
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <div class="card-face card-front">
        <slot name=front></slot>
      </div>
      <div class="card-face card-back">
        <slot name=back></slot>
      </div>
    </div>
  `;
}
