import { nonnull } from "./assert";
import { Constants } from "./constants";
import { DomUtils } from "./dom";
import { DomEventUtils } from "./dom-event-utils";
import { dev } from "./log";
import { Point } from "./point";
import { Session } from "./session";

export class GestureManager {
  private gestureLockHandle: Option<string>;
  private pressed: boolean = false;
  private $pressedElement: Option<HTMLElement>;
  private $capturedElement: Option<HTMLElement>;

  private gestureStartX: Option<number>;
  private gestureStartY: Option<number>;
  private gestureDragProgressX: Option<number>;
  private gestureDragProgressY: Option<number>;
  private documentPointerDragMove: Option<
    (e: { progressX: number; progressY: number }) => void
  >;
  private documentPointerDragUp: Option<
    (e: { progressX: number; progressY: number }) => void
  >;

  private onClickOutsideCallbacks: Map<
    HTMLElement,
    ($pointerUpElement: Option<HTMLElement>) => void
  > = new Map();

  constructor() {
    document.onpointerup = (e) => {
      const $target = e.target instanceof HTMLElement ? e.target : null;

      if (!this.pressed) {
        this.emitOnClickOutside($target, $target);
        this.$pressedElement = null;
        return;
      }
      this.pressed = false;
      this.gestureLockHandle = null;
      document.body.releasePointerCapture(e.pointerId);
      // document.body.style.removeProperty("user-select");
      if (!this.documentPointerDragUp) {
        this.emitOnClickOutside($target, $target);
        this.$pressedElement = null;
        return;
      }

      if (
        !this.gestureDragProgressX ||
        Math.abs(this.gestureDragProgressX) < 3 ||
        !this.gestureDragProgressY ||
        Math.abs(this.gestureDragProgressY) < 3
      ) {
        this.emitOnClickOutside($target, $target);
      }

      e.stopPropagation();
      this.documentPointerDragUp({
        progressX: nonnull(this.gestureStartX) - e.pageX,
        progressY: nonnull(this.gestureStartY) - e.pageY,
      });
      this.gestureDragProgressX = null;
      this.$pressedElement = null;
    };

    document.onpointermove = (e) => {
      if (!this.pressed) {
        return;
      }
      this.gestureDragProgressX = nonnull(this.gestureStartX) - e.pageX;
      this.gestureDragProgressY = nonnull(this.gestureStartY) - e.pageY;
      if (this.documentPointerDragMove) {
        e.stopPropagation();
        this.documentPointerDragMove({
          progressX: this.gestureDragProgressX,
          progressY: this.gestureDragProgressY,
        });
      }
    };
  }

  disablePointers($e: HTMLElement) {
    $e.onpointerdown = DomEventUtils.STOP_PROPAGATION;
    $e.onpointerup = DomEventUtils.STOP_PROPAGATION;
  }

  setPointerCapture($e: HTMLElement) {
    this.$capturedElement = $e;
  }

  clearPointerCapture($e: HTMLElement) {
    if (this.$capturedElement == $e) {
      this.$capturedElement = null;
    }
  }

  addPointerEvent($e: HTMLElement, config: GestureManager.PointerEvents) {
    GESTURE_MANAGER.addOnClick(
      $e,
      config.onClick ?? Constants.EMPTY_FUNCTION,
      config.onContextMenu,
      config.canHandleCapturedElement ?? (() => false),
      {
        onDoubleClick: config.onDoubleClick,
      }
    );
  }

  private addOnClick(
    $e: HTMLElement,
    onClick: ($currentlyCapturedElement: Option<HTMLElement>) => void,
    onContextMenu: Option<(p: Point) => void> = null,
    canHandleCapturedElement: ($e: HTMLElement) => boolean = () => false,
    options?: Option<{
      onDoubleClick?: Option<() => void>;
    }>
  ) {
    $e.oncontextmenu = onContextMenu
      ? (e) => {
          if (Session.isWindows) {
            onContextMenu({
              x: e.clientX,
              y: e.clientY,
            });
            e.stopPropagation();
          }
          e.preventDefault();
        }
      : null;
    $e.onpointerdown = (e) => {
      this.$pressedElement = $e;
      if (e.button == 0) {
        e.stopPropagation();
      } else {
        onContextMenu && e.stopPropagation();
      }
    };
    $e.ondblclick = options?.onDoubleClick
      ? (e) => {
          e.stopPropagation();
          if (e.button == 0) {
            options.onDoubleClick!();
          }
          this.$pressedElement = null;
        }
      : null;
    $e.onpointerup = (e) => {
      if (this.$capturedElement) {
        if (!canHandleCapturedElement($e)) {
          const cb = this.onClickOutsideCallbacks.get(this.$capturedElement);
          cb && cb($e);
          this.clearOnClickOutside($e);
          return;
        }
      }
      this.emitOnClickOutside(this.$pressedElement, $e);
      if (e.button == 0) {
        e.stopPropagation();
        onClick(this.$capturedElement);
      }
      if (e.button == 2 && !Session.isWindows) {
        onContextMenu && e.stopPropagation();
        onContextMenu &&
          onContextMenu({
            x: e.clientX,
            y: e.clientY,
          });
      }
      this.$pressedElement = null;
    };
  }

  setOnClickOutside($e: HTMLElement, cb: ($e: Option<HTMLElement>) => void) {
    this.onClickOutsideCallbacks.set($e, cb);
  }

  clearOnClickOutside($e: HTMLElement) {
    this.onClickOutsideCallbacks.delete($e);
  }

  toggleCleanUpOnClickOutside(
    $e: HTMLElement,
    cb: ($e: Option<HTMLElement>) => void,
    force: boolean = true
  ) {
    if (force) {
      GESTURE_MANAGER.setOnClickOutside($e, ($pointerUpElement) => {
        cb($pointerUpElement);
        GESTURE_MANAGER.clearOnClickOutside($e);
      });
    } else {
      GESTURE_MANAGER.clearOnClickOutside($e);
    }
  }

  cleanupCallback($e: HTMLElement) {
    const maybeCb = this.onClickOutsideCallbacks.get($e);
    if (!maybeCb) return;
    maybeCb(undefined);
  }

  private emitOnClickOutside(
    $pointerDownElement: Option<HTMLElement>,
    $target: Option<HTMLElement>
  ) {
    if ($pointerDownElement != $target) {
      return;
    }
    for (const [$e, cb] of this.onClickOutsideCallbacks.entries()) {
      if ($e == $target) {
        continue;
      }
      if (
        $pointerDownElement != null &&
        DomUtils.hasAncestor($pointerDownElement, $e)
      ) {
        continue;
      }
      cb($pointerDownElement ?? undefined);
    }
  }

  addDragGesture(
    $e: HTMLElement,
    id: string,
    cbs: {
      onPointerDown?: (e: PointerEvent) => void;
      onPointerMove: (e: { progressX: number; progressY: number }) => void;
      onPointerUp?: Option<
        (e: { progressX: number; progressY: number }) => void
      >;
    }
  ) {
    $e.onpointerdown = (e) => {
      if (this.gestureLockHandle != null) {
        return;
      }
      if (e.button !== 0) {
        return;
      }
      e.stopPropagation();
      this.gestureStartX = e.pageX;
      this.gestureStartY = e.pageY;
      cbs.onPointerDown && cbs.onPointerDown(e);

      document.body.setPointerCapture(e.pointerId);

      this.gestureLockHandle = id;
      this.pressed = true;

      this.documentPointerDragMove = cbs.onPointerMove;
      this.documentPointerDragUp = cbs.onPointerUp;

      document.body.style.userSelect = "none";
    };
  }
}

export const GESTURE_MANAGER = new GestureManager();

export namespace GestureManager {
  export type PointerEvents = {
    onClick?: ($currentlyCapturedElement: Option<HTMLElement>) => void;
    onContextMenu?: Option<(p: Point) => void>;
    onDoubleClick?: Option<EmptyFunction>;
    canHandleCapturedElement?: ($e: HTMLElement) => boolean;
  };
}
