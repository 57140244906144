import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { GESTURE_MANAGER } from "@lona/gesture-manager";
import { $vocabGrid, Actions } from "../routes/index.root";
import { Tooltip } from "@lona/components/tooltip";

@component({
  name: "langilo-actions-bar",
})
export class ActionsBar extends LonaWebComponent {
  bindPromptHasNewCards(cnt: number) {
    if (!$vocabGrid.hasAttribute("hide")) return;
    this.toggleAttribute("prompt-has-new-cards", cnt > 0);
  }

  connectedCallback() {
    GESTURE_MANAGER.addPointerEvent(this.$("new-cards-available"), {
      onClick: () => Actions.navigate("grid"),
    });
  }

  static $styles: HTMLTemplateElement[] = [
    Tooltip.$style,
    css`
      #root {
        align-items: start;
      }

      #streak {
        background: var(--card-color);
        margin-inline: 4px;
        margin-block: 0px;
        height: 32px;
        padding: 4px 8px;
        border-radius: 8px;
        align-items: center;
        position: relative;
      }

      #new-cards-available {
        color: white;
        background: #444;
        padding: 10px 12px;
        border-radius: 12px;
        height: 32px;
        margin-right: 4px;
        transition: width 0.3s ease, background-color 0.3s ease;
        user-select: none;
        cursor: pointer;
        opacity: 0;
      }

      #new-cards-available:hover {
        background: #333;
      }

      :host([prompt-has-new-cards]) #new-cards-available {
        opacity: 1;
      }

      #spacer {
        position: relative;
        transition: width 0.3s ease, opacity 0.3s ease;
        width: 0px;
        opacity: 0;
      }

      #new-cards-available:hover #spacer {
        width: 16px;
        opacity: 1;
      }

      #arrow {
        margin-left: 6px;
        margin-top: 1px;
        transform: rotate(-45deg);
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root>
      <std-row id=new-cards-available>
        <p>new cards available</p>
        <div id=spacer>
          <svg id="arrow" height="9px" class=arw-right viewBox="0 0 13 10" fill="black" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M12.125 5C12.125 4.81667 12.0417 4.64167 11.8917 4.5L7.59166 0.208332C7.44166 0.0666616 7.275 0 7.1 0C6.73334 0 6.45 0.274995 6.45 0.65C6.45 0.825002 6.50833 1 6.63333 1.11667L7.78333 2.30833L10.6 4.85L10.75 4.48334L8.53333 4.325H0.666671C0.275009 4.325 0 4.60834 0 5C0 5.39167 0.275009 5.67501 0.666671 5.67501H8.53333L10.75 5.51666L10.6 5.15833L7.78333 7.69167L6.63333 8.88333C6.50833 9 6.45 9.175 6.45 9.35C6.45 9.725 6.73334 10 7.1 10C7.275 10 7.44166 9.93333 7.59166 9.79167L11.8917 5.5C12.0417 5.35833 12.125 5.18333 12.125 5Z"/>
          </svg>
        </div>
      </std-row>
      <std-row id=streak>
        <p style=--margin-bottom:2px;margin-right:2px;>5</p>
        <p>🔥</p>
        <std-tooltip position=bottom style=left:-12px;>
        <div>Streaks</div>
        </std-tooltip>
      </std-row>
      <slot></slot>
    </std-row>
  `;
}
