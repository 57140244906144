import { dev, info } from "./log";
import { Result } from "./result";
import { TaggedEnum } from "./tagged-enum";

export type ResponseError = {
  code: number;
  reason: string;
};

export class ResponseResult<Data> extends Result<Data, ResponseError> {
  static expectOrToast<D>(r: ResponseResult<D>): D {
    return r.match({
      ok: (o) => o,
      err: () => {
        throw new Error("request failed");
      },
    });
  }
}

export interface TypedResponse<R> {
  response: Response;
  data: () => Promise<R>;
}

interface FetchConfig {
  query?: any;
  body?: any;
  path?: any;
}

interface Route {
  config?: FetchConfig;
  response?: Option<any>;
}

export interface RouteList {
  [route: string]: {
    get?: Route;
    post?: Route;
    patch?: Route;
    delete?: Route;
  };
}

export class TypedApi<R extends RouteList> {
  static _instance = new TypedApi();
  static instance<R extends RouteList>(): TypedApi<R> {
    return this._instance as unknown as TypedApi<R>;
  }

  async post<
    K extends keyof R,
    Config = R[K] extends { post: { config: infer C } } ? C : never,
    Response = R[K] extends { post: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<Response> {
    return this.wrappedSend("POST", route as string, config).then(
      ResponseResult.expectOrToast
    );
  }

  async postResult<
    K extends keyof R,
    Config = R[K] extends { post: { config: infer C } } ? C : never,
    Response = R[K] extends { post: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<ResponseResult<Response>> {
    return this.wrappedSend("POST", route as string, config);
  }

  async patch<
    K extends keyof R,
    Config = R[K] extends { patch: { config: infer C } } ? C : never,
    Response = R[K] extends { patch: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<Response> {
    return this.wrappedSend("PATCH", route as string, config).then(
      ResponseResult.expectOrToast
    );
  }

  async patchResult<
    K extends keyof R,
    Config = R[K] extends { patch: { config: infer C } } ? C : never,
    Response = R[K] extends { patch: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<ResponseResult<Response>> {
    return this.wrappedSend("PATCH", route as string, config);
  }

  async get<
    K extends keyof R,
    Config = R[K] extends { get: { config: infer C } } ? C : never,
    Response = R[K] extends { get: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<Response> {
    return this.wrappedSend("GET", route as string, config).then(
      ResponseResult.expectOrToast
    );
  }

  async getResult<
    K extends keyof R,
    Config = R[K] extends { get: { config: infer C } } ? C : never,
    Response = R[K] extends { get: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<ResponseResult<Response>> {
    return this.wrappedSend("GET", route as string, config);
  }

  async del<
    K extends keyof R,
    Config = R[K] extends { delete: { config: infer C } } ? C : never,
    Response = R[K] extends { delete: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<Response> {
    return this.wrappedSend("DELETE", route as string, config).then(
      ResponseResult.expectOrToast
    );
  }

  async delResult<
    K extends keyof R,
    Config = R[K] extends { delete: { config: infer C } } ? C : never,
    Response = R[K] extends { delete: { response: infer C } } ? C : never
  >(route: K, config?: Config): Promise<ResponseResult<Response>> {
    return this.wrappedSend("DELETE", route as string, config);
  }

  async wrappedSend<Config>(
    method: string,
    route: string,
    config?: Config
  ): Promise<Result<any, { code: number; reason: any }>> {
    const response = await TypedApi.send(
      method,
      route as string,
      (config as Option<FetchConfig>) ?? {}
    );
    if (response.status != 200) {
      return ResponseResult.err({
        code: response.status,
        reason: await TypedApi.jsonBody(response),
      });
    } else {
      return ResponseResult.ok(await TypedApi.jsonBody(response));
    }
  }

  static async jsonBody(response: Response): Promise<Option<any>> {
    try {
      return await response.json();
    } catch {
      return undefined;
    }
  }

  // NOTE: return type is type erased - no way for the compiler to check
  // the return type of sendMessage so this is correct
  //
  static async send(
    method: string,
    route: string,
    config: FetchConfig
  ): Promise<Response> {
    let resolvedRoute = route;
    const matches = [...route.matchAll(/{([^}]+)}/g)];
    for (const match of matches) {
      // match = ["{param}", "param"]
      // TODO
      // Need error handling - what if config.path.query is null?
      resolvedRoute = resolvedRoute.replace(match[0], config.path[match[1]]);
    }
    const params = new URLSearchParams();
    // const url = new URL(resolvedRoute);
    for (const p in config.query ?? {}) {
      params.append(p, config.query[p]);
    }
    info("network")(
      `[${method}]`,
      resolvedRoute,
      // config.body,
      // params.values()
      config.query
    );

    performance.mark(`[network][${route}] send start`);
    const r = await fetch(
      params.size > 0 ? `${resolvedRoute}?${params.toString()}` : resolvedRoute,
      {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(config.body),
      }
    );
    performance.mark(`[network][${route}] send end`);
    performance.measure(
      `[network][${route}] send`,
      `[network][${route}] send start`,
      `[network][${route}] send end`
    );

    return r;
  }
}
