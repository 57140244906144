import { MathUtils } from "../../../lona-js/lona-js/math";
import { Month, YearMonth } from "./month";
import { NaiveDate, YearMonthDay } from "../naive-date";
import {
  DayOfMonth0,
  DayOfYear0,
  DaysSinceEpoch,
  Month0,
  Month1,
} from "./units";
import { range } from "../range";

export namespace Year {
  export function dseFromYear(year: number): DaysSinceEpoch {
    return (365 * (year - 1970) +
      MathUtils.floorDiv(year - 1969, 4) -
      MathUtils.floorDiv(year - 1901, 100) +
      MathUtils.floorDiv(year - 1601, 400)) as DaysSinceEpoch;
  }

  export function isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  export function length(year: number): number {
    return Year.isLeapYear(year) ? 366 : 365;
  }

  export function doy(year: number): Generator<DayOfYear0> {
    return range(Year.length(year)) as Generator<DayOfYear0>;
  }

  export function isoStart(year: number): NaiveDate {
    const start = NaiveDate.fromYmd1Exp(year, 1, 1);
    const dow = start.dayOfWeek.isoDow;
    return start.addDays(dow <= 3 ? -dow : 7 - dow);
  }
}

export class YearInfo {
  readonly year: number;

  readonly isLeapYear: boolean;
  readonly numDays: number;

  readonly firstDay: YearMonthDay;

  constructor(year: number) {
    this.year = year;
    this.isLeapYear = Year.isLeapYear(year);
    this.numDays = this.isLeapYear ? 366 : 365;
    this.firstDay = YearMonthDay.fromYmd1Unchecked(year, 1, 1);
  }

  /**
   * generates (0..<=364/365)
   */
  doy(): Generator<DayOfYear0> {
    return range(this.numDays) as Generator<DayOfYear0>;
  }

  /**
   * generates (31..<=59)
   */
  doyForMonth(month: Month0): Generator<DayOfYear0> {
    return YearMonth.doyForMonth({ yr: this.year, mth: (month + 1) as Month1 });
  }

  /**
   * generates (0..<=30)
   */
  dom(month: Month0): Generator<DayOfMonth0> {
    return YearMonth.dom({ yr: this.year, mth: (month + 1) as Month1 });
  }
}
