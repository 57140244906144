import { LonaBaseStyles, createTemplate } from "./component-styles";
import { Flexbox } from "./components/flexbox";
import { CSSStyleWithVariables, DomUtils } from "./dom";
import { $, $_maybe, $qs, $qs_maybe, $qsa } from "./dom-selectors";
import { GESTURE_MANAGER } from "./gesture-manager";
import { Point } from "./point";
import { Typography } from "./ui/typography";

export const template = (
  s: TemplateStringsArray | string
): HTMLTemplateElement => {
  return createTemplate(`${s}`);
};

// https://stackoverflow.com/questions/34098023/typescript-self-referencing-return-type-for-static-methods-in-inheriting-classe
export interface LonaWebComponentClass<T extends LonaWebComponent> {
  componentName: string;
  new (): T;

  useBorderBox: boolean;

  $baseStyles: HTMLTemplateElement[];
  $styles: HTMLTemplateElement[];
  $icons: Option<string[]>;
  $iconsTemplate: Option<HTMLTemplateElement>;
  $html: Option<HTMLTemplateElement>;

  pool: Option<T[]>;
  make: <Subclass extends typeof LonaWebComponent>() => InstanceType<Subclass>;
  recycle: (instance: T) => void;

  // todo: lifecycles?
  // onRecycle: (instance: T) => void;
}

export class LonaWebComponent extends HTMLElement {
  // Config
  static useBorderBox: boolean = false;
  static componentName: string = "div";

  static $baseStyles: HTMLTemplateElement[] = [
    LonaBaseStyles.$borderBox,
    Flexbox.$style,
    Typography.$style,
  ];
  static $styles: HTMLTemplateElement[] = [];
  static $html: Option<HTMLTemplateElement>;
  static $icons: Option<string[]>;
  static $iconsTemplate: Option<HTMLTemplateElement>;
  static pool: Option<any[]>; // NOTE: the pool can't be initialized here otherwise everyone will share the same pool!!

  constructor() {
    super();
    // @ts-ignore
    const constructor: LonaWebComponentClass<any> = this.constructor;
    // @ts-ignore
    constructor.inflate(constructor, this);
  }

  static make<Subclass extends typeof LonaWebComponent>(
    this: Subclass
  ): InstanceType<Subclass> {
    const reused = this.pool?.pop();
    // info("ui")(`[${this.name}] pool:`, reused != null, this.componentName);
    return (reused ??
      document.createElement(this.componentName)) as InstanceType<Subclass>;
  }

  static recycle<T extends LonaWebComponent>(
    this: LonaWebComponentClass<T>,
    instance: T
  ) {
    performance.mark(`[lona-web-component] recycle start`);
    instance.parentElement?.removeChild(instance);
    performance.mark(`[lona-web-component] recycle end`);
    performance.measure(
      `[lona-web-component] recycle`,
      `[lona-web-component] recycle start`,
      `[lona-web-component] recycle end`
    );
    // 1) Wait until the current layout pass is over
    setTimeout(() => {
      requestIdleCallback(() =>
        // 2) Dispatch a mutate
        {
          instance.style.cssText = "";
          instance.innerHTML = "";
          while (instance.attributes.length > 0) {
            instance.removeAttribute(instance.attributes[0].name);
          }
          if (!this.pool) {
            this.pool = [instance];
          } else {
            this.pool.push(instance);
          }
        }
      );
    });
  }

  onRecycle() {}

  recycle() {
    // @ts-ignore
    const constructor: LonaWebComponentClass<any> = this.constructor;
    constructor.recycle(this);
  }

  private static inflate<T extends LonaWebComponent>(
    clazz: LonaWebComponentClass<T>,
    instance: T
  ) {
    const shadowRoot = instance.attachShadow({ mode: "open" });

    for (const sheet of clazz.$baseStyles) {
      shadowRoot.appendChild(sheet.content.cloneNode(true));
    }

    for (const sheet of clazz.$styles) {
      shadowRoot.appendChild(sheet.content.cloneNode(true));
    }

    if (clazz.$icons) {
      if (clazz.$iconsTemplate == null) {
        const icons = createTemplate(DomUtils.importSvgs(clazz.$icons));
        clazz.$iconsTemplate = icons;
        shadowRoot.appendChild(icons.content.cloneNode(true));
      } else {
        shadowRoot.appendChild(clazz.$iconsTemplate.content.cloneNode(true));
      }
    }

    clazz.$html && shadowRoot.appendChild(clazz.$html.content.cloneNode(true));
  }

  $<T extends HTMLElement = HTMLElement>(s: string): T {
    return $<T>(s, this.shadowRoot!);
  }
  $_maybe<T extends HTMLElement = HTMLElement>(s: string): Option<T> {
    return $_maybe<T>(s, this.shadowRoot!);
  }
  $qs<T extends HTMLElement = HTMLElement>(s: string): T {
    return $qs<T>(s, this.shadowRoot!);
  }
  $qs_maybe<T extends HTMLElement = HTMLElement>(s: string): Option<T> {
    return $qs_maybe<T>(s, this.shadowRoot!);
  }
  $qsa<T extends HTMLElement = HTMLElement>(s: string): NodeListOf<T> {
    return $qsa<T>(s, this.shadowRoot!);
  }

  addPointerEvent(config: {
    onClick?: ($currentlyCapturedElement: Option<HTMLElement>) => void;
    onContextMenu?: Option<(p: Point) => void>;
    onDoubleClick?: Option<EmptyFunction>;
    canHandleCapturedElement?: ($e: HTMLElement) => boolean;
  }): LonaWebComponent {
    GESTURE_MANAGER.addPointerEvent(this, config);
    return this;
  }

  assignAttributes(attributes: { [k: string]: string }): LonaWebComponent {
    return DomUtils.assignAttributes(this, attributes);
  }

  assignStyles(styles: CSSStyleWithVariables): LonaWebComponent {
    return DomUtils.assignStyles(this, styles);
  }
}

export namespace LonaWebComponent {
  export function asFactory<C extends LonaWebComponentClass<any>>(
    t: C
  ): Factory<InstanceType<C>> {
    return t.make.bind(t) as Factory<InstanceType<C>>;
  }
}
