import { MathUtils } from "@lona/math";
import { nonnull } from "@lona/assert";
import { Time } from "../time";

export type HmsLike = { hrs: number; mins: number; secs: number };
export type HmsLikeOpt = Optional<HmsLike>;

export namespace Hms {
  export function fromMs(ms: number): HmsLike {
    return {
      hrs: Math.floor(ms * Time.MS_PER_HR_INV),
      mins: Math.floor((ms % Time.MS_PER_HR) * Time.MS_PER_MIN_INV),
      secs: Math.floor((ms % Time.MS_PER_MIN) * Time.MS_PER_SEC_INV),
    };
  }

  export function toMs(hms: Optional<HmsLike>): number {
    return (
      (hms.hrs ?? 0) * Time.MS_PER_HR +
      (hms.mins ?? 0) * Time.MS_PER_MIN +
      (hms.secs ?? 0) * Time.MS_PER_SEC
    );
  }

  export function fromRfc3339(format: string): Optional<HmsLike> {
    const [hours, mins, seconds] = format.split(":");
    return {
      hrs: nonnull(MathUtils.numberOrNull(parseInt(hours))),
      mins: nonnull(MathUtils.numberOrNull(parseInt(mins))),
      secs: seconds ? parseInt(seconds) : null,
    };
  }

  export function toRfc3339(hms: Optional<HmsLike>): string {
    return [
      hms.hrs ? hms.hrs.toString().padStart(2, "0") : "00",
      hms.mins ? hms.mins.toString().padStart(2, "0") : "00",
      hms.secs ? hms.secs.toString().padStart(2, "0") : "00",
    ].join(":");
  }

  export function isStrict(hms: HmsLike): boolean {
    if (hms.hrs < 0 || hms.hrs >= 24) return false;
    if (hms.mins < 0 || hms.mins >= 60) return false;
    if (hms.secs < 0 || hms.secs >= 60) return false;
    return true;
  }
}
