import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { StudyCard } from "./study-card";

@component({
  name: "langilo-study-card-preview",
})
export class StudyCardPreview extends LonaWebComponent {
  static makeWith(card: StudyCard.Prop): StudyCardPreview {
    const $e = StudyCardPreview.make();
    $e.bind(card);
    return $e;
  }

  bind(card: StudyCard.Prop) {
    this.$("word").textContent = card.title;
    this.$("definition").textContent = card.definition;
  }

  static $styles: HTMLTemplateElement[] = [
    css`
      :host {
        width: 100%;
      }

      #root {
        width: 100%;
        padding-top: 8px;
      }

      #card {
        width: 100%;
        border-radius: 8px;
        background: white;
        color: var(--primary-text-color);
        padding: 16px;
      }

      #definition {
        --margin-top: 8px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root>
      <std-col id=card>
        <h2 id=word class=h6></h2>
        <p id=definition></h2>
      </std-col>
    </std-col>
  `;
}
