import {
  DateTimeRegion,
  NaiveDate,
  NaiveDateTime,
  NaiveTime,
  Weekday,
} from "../mod";
import { Result } from "../result";

export interface RRuleLike {
  freq: RRule.Frequency;
  dtstart: NaiveDateTime;
  options?: Optional<{
    wkst: Weekday;
    interval: number;
    bysetpos: number;
    byday: Weekday;
    until: NaiveDateTime;
    tzid: string;
  }>;
}

export class RRule {
  readonly rrule: RRuleLike;

  constructor(rrule: RRuleLike) {
    this.rrule = rrule;
  }

  static parse(ser: string): RRule.Fragment {
    return RRule.Fragment.parse(ser);
  }
}

export namespace RRule {
  export function tokenize(ser: string): string[] {
    // todo: handle more difficult splitting logic
    const PREFIX = "RRULE:";
    if (ser.startsWith(PREFIX)) {
      ser = ser.slice(PREFIX.length);
    }

    return ser.split(";");
  }

  export type FragmentLike = Optional<RRuleLike>;

  export class Fragment {
    readonly rrule: FragmentLike;

    constructor(rrule: FragmentLike) {
      this.rrule = rrule;
    }

    get isComplete() {
      return this.rrule.freq != null;
    }

    static parse(ser: string): Fragment {
      const rules = new Map();
      for (const rule of RRule.tokenize(ser)) {
        const [key, value] = rule.split("=");
        rules.set(key, value);
      }

      return new Fragment({
        freq: RRule.Frequency.parse(rules.get("FREQ")),
        options: {
          wkst: Weekday.parse(rules.get("WKST")),
          interval: RRule.parseNumberOpt(rules.get("INTERVAL")),
          bysetpos: RRule.parseNumberOpt(rules.get("BYSETPOS")),
          byday: Weekday.parse(rules.get("BYDAY")),
          tzid: rules.get("TZID"),
          until: rules.get("UNTIL")
            ? Result.opt(RRule.parseUtcNdt(rules.get("UNTIL")))
            : null,
        },
      });
    }

    toString(): string {
      let sb: string[] = [];

      const options = this.rrule.options;
      if (this.rrule.dtstart != null) {
        if (options?.tzid != null) {
          sb.push(
            `DTSTART;TZID=${options.tzid}:${RRule.ndtToString(
              this.rrule.dtstart,
              ""
            )}`
          );
        } else {
          sb.push(`DTSTART=${RRule.ndtToString(this.rrule.dtstart)}`);
        }
      }

      if (this.rrule.freq)
        sb.push(`FREQ=${RRule.Frequency.toString(this.rrule.freq)}`);
      if (options?.wkst) sb.push(`WKST=${Weekday.toString2cap(options.wkst)}`);
      if (options?.interval != null) sb.push(`INTERVAL=${options.interval}`);
      if (options?.bysetpos != null) sb.push(`BYSETPOS=${options.bysetpos}`);
      if (options?.byday != null)
        sb.push(`BYDAY=${Weekday.toString2cap(options.byday)}`);
      if (options?.until != null)
        sb.push(`UNTIL=${RRule.ndtToString(options.until)}`);

      return this.isComplete ? "RRULE:" + sb.join(";") : sb.join(";");
    }
  }

  export function parseNumberOpt(s: Option<string>): Option<number> {
    if (!s) return null;
    const n = parseInt(s);
    if (isNaN(n) || !isFinite(n)) return null;
    return n;
  }

  export const frequencies = [
    "secondly",
    "minutely",
    "hourly",
    "daily",
    "weekly",
    "monthly",
    "yearly",
  ] as const;
  export type Frequency = (typeof frequencies)[number];
  export const frequencySet: Set<string> = new Set(frequencies);

  export namespace Frequency {
    export function parse(s: Option<string>): Option<Frequency> {
      if (!s) return null;
      const lower = s.toLowerCase();
      return frequencySet.has(lower) ? (lower as Frequency) : null;
    }

    export function toString(freq: Frequency): string {
      return freq.toUpperCase();
    }
  }

  export function parseUtcNdt(ndts: string): Result<NaiveDateTime> {
    const regex = /^(\d{4})(\d{2})(\d{2})(?:T(\d{2})(\d{2})?(\d{2})?)?Z?$/;
    const match = ndts.match(regex);
    if (!match) return Error("no match");

    const [_, year, month, day, hrs, mins, secs] = match;

    const ndr = NaiveDate.fromYmd1Str(year, month, day);
    if (Result.isErr(ndr)) return ndr as Error;
    const nd = Result.opt(ndr)!;

    const ntr = NaiveTime.fromHmsStr(hrs, mins, secs);
    if (Result.isErr(ntr)) return ntr as Error;
    const nt = Result.opt(ntr)!;

    return new NaiveDateTime(nd, nt);
  }

  export function ndtToString(
    ndt: NaiveDateTime,
    offset: string = "Z"
  ): string {
    return [
      String(ndt.ymd.yr),
      String(ndt.ymd.mth).padStart(2, "0"),
      String(ndt.ymd.day).padStart(2, "0"),
      "T",
      String(ndt.timeOfDay.hrs).padStart(2, "0"),
      String(ndt.timeOfDay.mins).padStart(2, "0"),
      String(ndt.timeOfDay.secs).padStart(2, "0"),
      offset,
    ].join("");
  }

  export function ndtzToString(ndtz: DateTimeRegion): string {
    if (ndtz.tz.fullname == "UTC") {
      return RRule.ndtToString(ndtz.ndt);
    } else {
      return `TZID=${ndtz.tz.fullname}:${RRule.ndtToString(ndtz.ndt, "")}`;
    }
  }
}
