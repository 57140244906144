import { DateTime } from "./datetime";

export namespace Cookies {
  export function set(
    name: string,
    options?: Optional<{
      value: string;
      days: number;
      maxAgeSec: number;
      domain: string;
    }>
  ) {
    let cookieBuilder = `${name}=${options?.value ?? ""};`;
    if (options?.days != null) {
      cookieBuilder += ` Expires=${DateTime.nowUTC()
        .add({ days: options.days })
        .rfc3339()};`;
    }
    if (options?.maxAgeSec != null) {
      cookieBuilder += ` Max-Age=${options.maxAgeSec};`;
    }
    if (options?.domain) {
      cookieBuilder += ` domain=${options.domain};`;
    }
    cookieBuilder += " path=/";
    document.cookie = cookieBuilder;
  }

  export function has(name: string): boolean {
    return Cookies.get(name) != null;
  }

  export function get(name: string): Option<string> {
    const nameq = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(nameq) == 0) {
        return c.substring(nameq.length, c.length);
      }
    }
    return null;
  }

  export function toggle(
    name: string,
    options: Optional<{
      domain: string;
      force: boolean;
    }>
  ): boolean {
    const on = options?.force != null ? options?.force : Cookies.has(name);
    if (on) {
      Cookies.set(name, {
        maxAgeSec: 999999999,
        domain: options.domain,
      });
    } else {
      Cookies.remove(name, options.domain);
    }
    return Cookies.get(name) != null;
  }

  export function remove(name: string, domain?: Option<string>) {
    // NOTE: path must match how the cookie was created!!
    let cookie = name + "=;";
    if (domain) {
      cookie += ` domain=${domain};`;
    }
    cookie += " Max-Age=-99999999; path=/";
    document.cookie = cookie;
  }

  window["Cookies"] = Cookies;
}
