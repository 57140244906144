import { uuidv4 } from "./crypto";

export namespace UserAgent {
  export enum UserAgentPlatform {
    Windows,
    Mac,
    Unknown,
  }

  export function getPlatform(): UserAgentPlatform {
    if (navigator.userAgent.includes("Windows")) {
      return UserAgentPlatform.Windows;
    } else if (navigator.userAgent.includes("Macintosh")) {
      return UserAgentPlatform.Mac;
    }
    return UserAgentPlatform.Unknown;
  }
}

export namespace Session {
  export const id = uuidv4();
  export const platform = UserAgent.getPlatform();
  export const isWindows = platform == UserAgent.UserAgentPlatform.Windows;
  export const isMacOs = platform == UserAgent.UserAgentPlatform.Mac;
  export const isSafari =
    // navigator.vendor &&
    // navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1;

  switch (platform) {
    case UserAgent.UserAgentPlatform.Windows:
      document.documentElement.setAttribute("platform", "windows");
      break;
    case UserAgent.UserAgentPlatform.Mac:
      document.documentElement.setAttribute("platform", "mac");
      break;
    case UserAgent.UserAgentPlatform.Unknown:
      break;
  }
}
