import { MathUtils } from "@lona/math";
import { DateTime, GenericRange, LogicalTimezone, range, Result } from "./mod";
import { Time } from "./time";
import {
  DateUnit,
  DateUnitLike,
  DateUnitLikeOpt,
  DateUnitType,
  ScalarDateUnit,
} from "./units/date-unit";
import { Month, YearMonth, Ym1Like } from "./units/month";
import {
  Day,
  DayOfMonth,
  DayOfMonth0,
  DayOfMonth1,
  DayOfWeek1,
  DayOfYear0,
  DayOfYear1,
  DaysSinceEpoch,
  Month0,
  Month1,
  MonthOfYear,
  MsSinceEpoch,
} from "./units/units";
import { Weekday } from "./units/weekday";
import { Year } from "./units/year";

export type CachedYmdInfo = {
  dayOfWeek: Weekday;
};

export interface YmdLike<Index> {
  readonly yr: number;
  readonly mth: MonthOfYear<Index>;
  readonly day: DayOfMonth<Index>;
}

export type YmdPartialLike<Index> = Optional<YmdLike<Index>>;
export type Ymd1Like = YmdLike<1>;
export type Ymd0Like = YmdLike<0>;

export class YearMonthDay implements Ymd1Like {
  readonly ymd1: Ymd1Like;
  readonly cached: Optional<CachedYmdInfo>;

  constructor(ymd1: Ymd1Like, info: Option<CachedYmdInfo> = null) {
    this.ymd1 = ymd1;
    this.cached = info ?? {};
  }

  check(): Result<NaiveDate> {
    if (this.month1 < 1 || this.month1 > 12) return Error("month/bounds");
    if (!YearMonth.isDayValid(this, this.day)) return Error("day/bounds");
    return this as unknown as NaiveDate;
  }

  assertValid(): NaiveDate {
    return Result.unwrap(this.check());
  }

  isValid(): boolean {
    return Result.isOk(this.check());
  }

  castValid(): NaiveDate {
    return this as unknown as NaiveDate;
  }

  castMaybeInValid(): NaiveDateMaybeValid {
    return this as unknown as NaiveDateMaybeValid;
  }

  /*
   * [Constructors]
   */
  static fromYmd1Unchecked(
    year: number,
    month1: number,
    day1: number
  ): NaiveDateMaybeValid {
    return new YearMonthDay({
      yr: year,
      mth: month1 as Month1,
      day: day1 as DayOfMonth1,
    }) as NaiveDateMaybeValid;
  }

  static fromYmd1Exp(year: number, month1: number, day1: number): NaiveDate {
    return Result.unwrap(this.fromYmd1Unchecked(year, month1, day1).check());
  }

  static fromYmd1(
    year: number,
    month1: number,
    day1: number
  ): Result<NaiveDate> {
    return this.fromYmd1Unchecked(year, month1, day1).check();
  }

  static fromYmd1Str(
    year: string,
    month1: string,
    day1: string
  ): Result<NaiveDate> {
    const yr = parseInt(year);
    if (isNaN(yr)) return Error(`parse/yr: ${year}`);

    const mth = parseInt(month1);
    if (isNaN(mth)) return Error(`parse/mth: ${month1}`);

    const day = parseInt(day1);
    if (isNaN(day)) return Error(`parse/day: ${day}`);

    return YearMonthDay.fromYmd1(yr, mth, day);
  }

  static fromYmd0(
    year: number,
    month0: number,
    day0: number
  ): NaiveDateMaybeValid {
    return new YearMonthDay({
      yr: year,
      mth: (month0 + 1) as Month1,
      day: (day0 + 1) as DayOfMonth1,
    }) as NaiveDateMaybeValid;
  }

  static fromDse(dse: DaysSinceEpoch): NaiveDate {
    return new YearMonthDay(Ymd.fromDse(dse)) as NaiveDate;
  }

  static fromMse(mse: MsSinceEpoch): NaiveDate {
    return new YearMonthDay(
      Ymd.fromDse(Math.floor(mse * Time.MS_PER_DAY_INV) as DaysSinceEpoch)
    ) as NaiveDate;
  }

  /**
   * [Interface] Ymd1
   */
  get yr(): number {
    return this.ymd1.yr;
  }

  get mth(): Month1 {
    return this.ymd1.mth;
  }

  get day(): DayOfMonth1 {
    return this.ymd1.day;
  }

  /**
   * [Getters] Month
   */
  get month0(): Month0 {
    return (this.ymd1.mth - 1) as Month0;
  }

  get month1(): Month1 {
    return this.ymd1.mth;
  }

  get dayOfMonth(): Month1 {
    return this.ymd1.mth;
  }

  /**
   * [Getters] Week-Day
   */
  get dayOfWeek(): Weekday {
    if (this.cached.dayOfWeek) return this.cached.dayOfWeek;
    return (this.cached.dayOfWeek =
      Weekday.DOW1[YearMonthDay.dayOfWeek(this) - 1]);
  }

  /**
   * [Getters] Month-Day
   */
  get day0(): DayOfMonth0 {
    return (this.ymd1.day - 1) as DayOfMonth0;
  }

  get day1(): DayOfMonth1 {
    return this.ymd1.day;
  }

  /**
   * [Getters] Year-Day
   */
  get dayOfYear(): DayOfYear1 {
    return YearMonthDay.dayOfYear(this);
  }

  get daysSinceEpoch(): DaysSinceEpoch {
    return YearMonthDay.daysSinceEpoch(this.ymd1);
  }

  get dse(): DaysSinceEpoch {
    return YearMonthDay.daysSinceEpoch(this.ymd1);
  }

  get mse(): MsSinceEpoch {
    return (this.dse * Time.MS_PER_DAY) as MsSinceEpoch;
  }

  /**
   * Ops
   */
  static addDays<Valid>(nd: NaiveDate<Valid>, days: number): NaiveDate<Valid> {
    /**
     * If the original date is
     *    ...invalid, it will become MaybeValid
     *    ...valid, it will stay valid
     *    ...maybeInvalid, it will stay maybeInvalid
     *
     * If the caller wants to promote an invalid day to an valid day by adding
     * days, they must call .check(). This function (and the typesystem) will
     * not be able to do it automatically.
     */
    if (days == 0) return nd;
    return NaiveDate.fromDse(
      (nd.dse + days) as DaysSinceEpoch
    ) as NaiveDate<Valid>;
  }

  addYears(yrs: number): NaiveDateMaybeValid {
    if (yrs == 0) return this.castMaybeInValid();
    return NaiveDate.fromYmd1Unchecked(this.yr + yrs, this.month1, this.day);
  }

  addMonths(mths: number): NaiveDateMaybeValid {
    if (mths == 0) return this.castMaybeInValid();

    let yr = this.yr;
    let mth = (mths + this.mth) as Month1;
    if (mth > 12 || mth <= 0) {
      const mod = MathUtils.mod(mth, 12);
      const div = MathUtils.floorDiv(mth, 12);
      yr += div;
      if (mod == 0) {
        mth = 12 as Month1;
        yr -= 1;
      } else {
        mth = mod as Month1;
      }
    }
    return NaiveDate.fromYmd1Unchecked(yr, mth, this.day);
  }

  addDays(days: number): NaiveDateMaybeValid {
    if (days == 0) return this.castMaybeInValid();

    let d = this.day + days;
    let yr = this.ymd1.yr;
    let mth = this.ymd1.mth;

    let daysInMonth = YearMonth.daysInMonth({ yr, mth });
    while (d > daysInMonth) {
      d -= daysInMonth;
      mth = (mth + 1) as Month1;
      if (mth > 12) {
        mth = 1 as Month1;
        yr += 1;
      }
      daysInMonth = YearMonth.daysInMonth({ yr, mth });
    }

    while (d <= 0) {
      d += daysInMonth;
      mth = (mth - 1) as Month1;
      if (mth <= 0) {
        mth = 12 as Month1;
        yr -= 1;
      }
      daysInMonth = YearMonth.daysInMonth({ yr, mth });
    }

    return NaiveDate.fromYmd1Unchecked(yr, mth, d);
  }

  addWeeks(weeks: number): NaiveDateMaybeValid {
    return this.addDays(weeks * 7);
  }

  add(delta: DateUnitLikeOpt): NaiveDateMaybeValid {
    let dt = this.castMaybeInValid();
    if (delta.yrs != null && delta.yrs != 0) {
      dt = dt.addYears(delta.yrs);
    }

    if (delta.mths != null && delta.mths != 0) {
      dt = dt.addMonths(delta.mths);
    }

    if (delta.wks != null && delta.wks != 0) {
      dt = dt.addDays(delta.wks * 7);
    }

    if (delta.days != null && delta.days != 0) {
      dt = dt.addDays(delta.days);
    }

    return dt;
  }

  differenceInDays(other: NaiveDate): number {
    return this.dse - other.dse;
  }

  differenceInMonths(other: NaiveDateMaybeValid): number {
    return (this.yr - other.yr) * 12 + (this.mth - this.mth);
  }

  cmpInvalid(other: NaiveDateMaybeValid): number {
    if (this.yr != other.yr) return this.yr - other.yr;
    if (this.mth != other.mth) return this.mth - other.mth;
    return this.day - other.day;
  }

  /**
   * For a given startdate: 2024-11-25
   *
   * Generates:
   *   Years: 2024, 2025, 2026, ...
   *   Months: 2024/11, 2024/12, 2025/1, ...
   *   Days: 2024/11/25, 2024/11/26, ...
   *   Weeks: 2024/11/25, 2024/12/2, ...
   *
   */
  succ(
    step: ScalarDateUnit<any>,
    recurLimit: number = 10_000
  ): Generator<NaiveDateMaybeValid> {
    let curr: NaiveDateMaybeValid = this as unknown as NaiveDateMaybeValid;
    return (function* () {
      for (let i = 0; i < recurLimit; ++i) {
        const next = curr.add(step);
        yield curr;
        curr = next;
      }
    })();
  }

  range(
    step: ScalarDateUnit<any> = ScalarDateUnit.days(1),
    options: Option<
      Optional<{
        end: NaiveDate; // inclusive
        limit: number;
        recurLimit: number;
        filter: YearMonthDay.Filter;
      }>
    > = null
  ): Generator<NaiveDate> {
    let recurCnt = 0;
    const recurLimit = options?.recurLimit ?? 10_000;
    const start = this.castMaybeInValid();
    const filter = options?.filter ?? YearMonthDay.Filter.identity();

    return (function* () {
      let cnt = 0;
      for (const i of start.succ(step, recurLimit)) {
        for (const [ndu, _] of filter(i, step.type)) {
          const nd = Result.opt(ndu.check());
          if (!nd) continue;
          if (nd.cmpInvalid(start) < 0) continue;
          if (options?.end && nd.cmpInvalid(options.end) > 0) continue;
          yield nd;
          cnt += 1;
          recurCnt += 1;
          if (options?.limit && cnt >= options.limit) return;
          if (recurCnt >= recurLimit) return;
        }
      }
    })();
  }

  /**
   * toString
   */
  toString(): string {
    return this.rfc3339();
  }

  rfc3339(): string {
    return [
      this.yr,
      String(this.month1).padStart(2, "0"),
      String(this.day1).padStart(2, "0"),
    ].join("-");
  }
}

export function naivedate(year: number, mth1: number, day1: number): NaiveDate {
  return Result.unwrap(NaiveDate.fromYmd1(year, mth1, day1));
}

export type NaiveDate<Valid = "valid"> = YearMonthDay & {
  readonly __valid: Valid;
};
export type NaiveDateMaybeValid = NaiveDate<any>;
export type NaiveDateInvalid = NaiveDate<"invalid">;

export const NaiveDate = YearMonthDay;

export namespace YearMonthDay {
  export function daysSinceEpoch(ymd: Ymd1Like): DaysSinceEpoch {
    return (Year.dseFromYear(ymd.yr) +
      YearMonth.doyForMonthStart(ymd) +
      ymd.day -
      1) as DaysSinceEpoch;
  }
  export const dse = daysSinceEpoch;

  export function dayOfWeek(ymd: Ymd1Like): DayOfWeek1 {
    const weekday = Weekday.fromDse(YearMonthDay.dse(ymd));
    return (weekday != 0 ? weekday : 7) as DayOfWeek1;
  }

  export function dayOfYear(ymd: Ymd1Like): DayOfYear1 {
    return (Month.MONTH_START_OF_YEAR[Year.isLeapYear(ymd.yr) ? 1 : 0][
      ymd.mth - 1
    ] + ymd.day) as DayOfYear1;
  }

  export type Filter = (
    partial: NaiveDateMaybeValid,
    unit: DateUnitType
  ) => Generator<[NaiveDateMaybeValid, DateUnitType]>;

  export namespace Filter {
    export function identity(): Filter {
      return (partial: NaiveDateMaybeValid, _unit: DateUnitType) =>
        (function* () {
          yield [partial, "days"];
        })();
    }

    export function compose(filters: Filter[]): Filter {
      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          const generators: Generator<[NaiveDateMaybeValid, DateUnitType]>[] =
            [];

          let curr: [NaiveDateMaybeValid, DateUnitType] = [partial, unit];
          for (const [idx, f] of filters.entries()) {
            const gen = f(curr[0], curr[1]);
            const next = gen.next();
            if (next.done) break;
            generators.push(gen);
            curr = next.value;
            if (idx == filters.length - 1) yield curr;
          }

          while (true) {
            if (generators.length == filters.length) {
              const leaf = generators.pop();
              if (!leaf) return;
              for (const value of leaf) yield value;
            }

            const top = generators.pop();
            if (!top) break;

            const next = top.next();
            if (next.done) continue;

            curr = next.value;
            generators.push(top);

            const gen = filters[generators.length]!(curr[0], curr[1]);
            generators.push(gen);
          }
        })();
    }

    export function byWeekday(dayOfWeeks: Weekday[]): Filter {
      const dow1: Option<boolean>[] = [];
      for (const dow of dayOfWeeks) {
        dow1[dow.dow] = true;
      }

      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          switch (unit) {
            case "years":
              // TODO: we can use a better generator for this instead of checking
              // each day
              for (const day of range(Year.length(partial.yr))) {
                const nd = partial.addDays(day - 1);
                if (dow1[nd.dayOfWeek.dow]) yield [nd, "days"];
              }
              break;
            case "months":
              for (const day of YearMonth.dom(partial)) {
                const nd = NaiveDate.fromYmd1Unchecked(
                  partial.yr,
                  partial.mth,
                  day
                );
                if (dow1[nd.dayOfWeek.dow]) yield [nd, "days"];
              }
              break;
            case "weeks":
              for (let i = 0; i < 7; ++i) {
                const day = partial.addDays(i);
                if (dow1[day.dayOfWeek.dow]) yield [day, "days"];
              }
              break;
            case "days":
              if (dow1[partial.dayOfWeek.dow]) yield [partial, "days"];
              break;
          }
        })();
    }

    export function byMonthOfYear(months: Month1[]): Filter {
      const mths = new Set(months);
      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          switch (unit) {
            case "years":
              const start = NaiveDate.fromYmd1Exp(partial.yr, 1, partial.day);
              for (const mth of start.succ(ScalarDateUnit.months(1), 12)) {
                if (mths.has(mth.mth)) yield [mth, "months"];
              }
              break;
            case "months":
              if (mths.has(partial.mth)) yield [partial, "months"];
              break;
            case "weeks":
              if (mths.has(partial.mth)) yield [partial, "weeks"];
              break;
            case "days":
              if (mths.has(partial.mth)) yield [partial, "days"];
              break;
          }
        })();
    }

    export function byDayOfMonth(dayOfMonths: DayOfMonth1[]): Filter {
      const domsPos = dayOfMonths.filter((doy) => doy >= 0);
      domsPos.sort((a, b) => a - b);
      const domsNeg = dayOfMonths.filter((doy) => doy < 0);
      domsNeg.sort((a, b) => b - a);
      const makeDoms = (ym1: Ym1Like): DayOfMonth1[] => {
        const monthLen = YearMonth.daysInMonth(ym1);
        const doms = [
          ...domsPos,
          ...domsNeg.map((dom) => monthLen + dom + 1),
        ] as DayOfMonth1[];
        doms.sort();
        return doms;
      };
      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          switch (unit) {
            case "years":
              const start = NaiveDate.fromYmd1Exp(partial.yr, 1, 1);
              for (const mth of start.succ(ScalarDateUnit.months(1), 12)) {
                for (const day of makeDoms(mth)) {
                  yield [
                    NaiveDate.fromYmd1Unchecked(mth.yr, mth.mth, day),
                    "days",
                  ];
                }
              }
              break;
            case "months":
              for (const day of makeDoms(partial)) {
                yield [
                  NaiveDate.fromYmd1Unchecked(partial.yr, partial.mth, day),
                  "days",
                ];
              }
              break;
            case "weeks":
              // todo: pretty inefficient - mem/hashmap?
              for (let i = 0; i < 7; ++i) {
                const date = partial.add({ days: i - 1 });
                const doms = makeDoms(date);
                if (doms.includes(date.day)) yield [date, "days"];
              }
              break;
            case "days":
              if (makeDoms(partial).includes(partial.day))
                yield [partial, "days"];
              break;
          }
        })();
    }

    export function byDayOfYear(dayOfYears: DayOfYear1[]): Filter {
      const doysPos = dayOfYears.filter((doy) => doy >= 0);
      const doysNeg = dayOfYears.filter((doy) => doy < 0);

      const doys365 = [...doysPos, ...doysNeg.map((doy) => 365 + doy + 1)];
      doys365.sort();
      const doys366 = [...doysPos, ...doysNeg.map((doy) => 365 + doy + 1)];
      doys366.sort();

      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          const yr = partial.yr;
          const doys = Year.isLeapYear(yr) ? doys366 : doys365;
          for (const doy of doys) {
            const date = NaiveDate.fromYmd1Exp(yr, 1, 1).addDays(doy - 1);
            switch (unit) {
              case "years":
                if (date.yr == partial.yr) yield [date, "days"];
              case "months":
                if (date.mth == partial.mth) yield [date, "days"];
              case "weeks":
              case "days":
                if (date.cmpInvalid(partial) == 0) yield [date, "days"];
            }
          }
        })();
    }

    export function byWeekNo(weekno1: number): Filter {
      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          const shouldAccept = (nd: NaiveDateMaybeValid): boolean => {
            switch (unit) {
              case "years":
                return true;
              case "months":
                return nd.mth == partial.mth;
              case "weeks":
              case "days":
                return nd.mth == partial.mth && nd.day == partial.day;
            }
          };

          if (weekno1 >= 0) {
            const start = Year.isoStart(partial.yr);
            if (weekno1 >= 53) {
              const end = Year.isoStart(partial.yr + 1);
              const numWeeks = Math.floor((end.dse - start.dse) / 7);
              if (numWeeks < weekno1) return;
            }

            const dayRange: GenericRange<number> = {
              start: (weekno1 - 1) * 7,
              end: weekno1 * 7,
            };

            for (let s = dayRange.start; s < dayRange.end; ++s) {
              const d = start.addDays(s);
              if (!shouldAccept(d)) continue;
              yield [d, "days"];
            }
          } else {
            const next = Year.isoStart(partial.yr + 1);
            const dayRange: GenericRange<number> = {
              start: weekno1 * 7,
              end: (weekno1 + 1) * 7,
            };

            for (let s = dayRange.start; s < dayRange.end; ++s) {
              const d = next.addDays(s);
              if (!shouldAccept(d)) continue;
              yield [d, "days"];
            }
          }
        })();
    }

    export function byNthWeekday(
      dayOfWeeks: Weekday.Nth[],
      rrulejs: boolean = true
    ): Filter {
      return (partial: NaiveDateMaybeValid, unit: DateUnitType) =>
        (function* () {
          switch (unit) {
            case "years":
              {
                const wrap = Year.length(partial.yr);
                const start = NaiveDate.fromYmd1Exp(partial.yr, 1, 1);
                const end = NaiveDate.fromYmd1Exp(partial.yr, 12, 31);
                const days = Weekday.nthForYear(dayOfWeeks, start, end, wrap);

                for (const ndow of days) {
                  yield [start.add({ days: ndow }), "days"];
                }
              }
              break;
            case "months":
              {
                const wrap = YearMonth.daysInMonth(partial);
                const start = NaiveDate.fromYmd1Exp(partial.yr, partial.mth, 1);
                const end = NaiveDate.fromYmd1Exp(
                  partial.yr,
                  partial.mth,
                  wrap
                );
                const days = Weekday.nthForYear(dayOfWeeks, start, end, wrap);

                for (const ndow of days) {
                  yield [start.add({ days: ndow }), "days"];
                }
              }
              break;
            case "weeks": {
              // todo:
              //
              // if (rrulejs) {
              //   // todo: pretty inefficient - mem/hashmap?
              //   for (let i = 0; i < 7; ++i) {
              //     const date = partial.add({ days: i - 1 });
              //     const doms = makeDoms(date);
              //     if (doms.includes(date.day)) yield [date, "days"];
              //   }
              //   // for (const ndow of dayOfWeeks) {
              //   //   if (ndow.weekday == partial.dayOfWeek)
              //   //     yield [partial, "days"];
              //   // }
              //   return;
              // }
            }
            case "days":
              {
                if (rrulejs) {
                  for (const ndow of dayOfWeeks) {
                    if (ndow.weekday == partial.dayOfWeek)
                      yield [partial, "days"];
                  }
                  return;
                }

                // NOTE: represents Nth of every month
                //
                const wrap = YearMonth.daysInMonth(partial);
                const start = NaiveDate.fromYmd1Exp(partial.yr, partial.mth, 1);
                const end = NaiveDate.fromYmd1Exp(
                  partial.yr,
                  partial.mth,
                  wrap
                );
                const days = Weekday.nthForYear(dayOfWeeks, start, end, wrap);

                const ndowDays = days.map((days) => start.add({ days }));
                for (const ndow of ndowDays) {
                  if (ndow.cmpInvalid(partial) == 0) yield [ndow, "days"];
                }
              }
              break;
          }
        })();
    }
  }
}

export namespace Ymd {
  const CYCLE_IN_YEARS = 400;
  const CYCLE_IN_DAYS = 146097;
  const CYCLE_IN_DAYS_INV = 1 / CYCLE_IN_DAYS;

  const RATA_DIE_1970_JAN1 = 719468;

  const s = 3670;
  const K = RATA_DIE_1970_JAN1 + s * CYCLE_IN_DAYS;
  const L = s * CYCLE_IN_YEARS;

  const U16_MAX = 65536;
  const U16_MAX_INV = 1 / U16_MAX;

  const U32_MAX = 4294967296;
  const U32_MAX_INV = 1 / U32_MAX;

  const C_2939745 = 2939745;
  const C_2939745_INV = 1 / C_2939745;

  const C_4_INV = 1 / 4;

  const C_2141 = 2141;
  const C_2141_INV = 1 / C_2141;

  //
  // js::ToYearMonthDay
  // https://github.com/mozilla/gecko-dev/blob/master/js/src/jsdate.cpp
  //
  // Adapted from Mozilla's temporal code.
  //
  // Fuzzy tested from Epoch(0...3000-1-1) against a Rust reference implementation
  //
  export function fromDseGecko(dse: DaysSinceEpoch): Ymd1Like {
    const N_U = dse;
    const N = N_U + K;

    const N_1 = 4 * N + 3;
    const C = Math.floor(N_1 * CYCLE_IN_DAYS_INV);
    const N_C = Math.floor((N_1 % CYCLE_IN_DAYS) * C_4_INV);

    // Year of the century Z and day of the year N_Y:
    const N_2 = 4 * N_C + 3;
    const P_2 = C_2939745 * N_2;
    const Z = Math.floor(P_2 * U32_MAX_INV);
    const N_Y = Math.floor(
      Math.floor((P_2 % U32_MAX) * C_2939745_INV) * C_4_INV
    );

    const Y = 100 * C + Z;
    const N_3 = C_2141 * N_Y + 132377; // 132377 = 197913 - 65536
    const M = Math.floor(N_3 * U16_MAX_INV);
    const D = Math.floor((N_3 % U16_MAX) * C_2141_INV);

    const daysFromMar01ToJan01 = 306;
    const J = N_Y >= daysFromMar01ToJan01;
    const Y_G = Math.floor(Y - L + (J ? 1 : 0));
    const M_G = J ? M - 12 : M;
    const D_G = D;

    return {
      yr: Y_G,
      mth: (M_G + 1) as Month1,
      day: (D_G + 1) as DayOfMonth1,
    };
  }

  export const fromDse = fromDseGecko;
}
