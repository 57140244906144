export const createTemplate = (htmlString: string) => {
  const template = document.createElement("template");
  template.innerHTML = htmlString;
  return template;
};

// todo: should we memo this?
export const css = (s: TemplateStringsArray | string): HTMLTemplateElement => {
  return createTemplate(/* html */ `<style>${s}</style>`);
};

export const LonaBaseStyles = {
  $borderBox: css`
    * {
      box-sizing: border-box;
    }
    :host {
      box-sizing: border-box;
    }
  `,
};
