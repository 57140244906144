export type Result<T> = T | Error;

export namespace Result {
  export function isErr<T>(r: Result<T>): boolean {
    return r instanceof Error;
  }

  export function isOk<T>(r: Result<T>): boolean {
    return !(r instanceof Error);
  }

  export function unwrap<T>(r: Result<T>): T {
    if (r instanceof Error) throw r;
    return r as T;
  }

  export function opt<T>(r: Result<T>): Option<T> {
    if (isOk(r)) return r as T;
    return null;
  }
}
