import { NaiveDate, NaiveTime, Result, Time, TimeUnit, Weekday } from "./mod";
import { NaiveDateTime } from "./naive-datetime";
import { LogicalTimezone, Utc, Local, NamelessFixedTimezone } from "./timezone";
import { DaysSinceEpoch, Ms, MsSinceEpoch } from "./units/units";

const RFC3339_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;

export class DateTime<TzType extends string> {
  static utc = Utc;
  static local = Local;

  readonly dt: NaiveDateTime;
  readonly tz: LogicalTimezone<TzType>;

  constructor(dt: NaiveDateTime, tz: LogicalTimezone<TzType>) {
    this.dt = dt;
    this.tz = tz;
  }

  static fromMse<TzType extends string>(
    mse: MsSinceEpoch,
    tz: LogicalTimezone<TzType>
  ): DateTime<TzType> {
    if (tz.info.offset.asMs == 0) {
      return new DateTime(NaiveDateTime.fromMse(mse), tz);
    }
    return new DateTime(
      NaiveDateTime.fromMse((mse + tz.info.offset.signedAsMs) as MsSinceEpoch),
      tz
    );
  }

  static fromDse<TzType extends string>(
    dse: DaysSinceEpoch,
    tz: LogicalTimezone<TzType>
  ): DateTime<TzType> {
    return DateTime.fromMse((dse * Time.MS_PER_DAY) as MsSinceEpoch, tz);
  }

  rfc3339(): string {
    const sb: string[] = ["", "T", "", ""];
    sb[0] = this.dt.ymd.rfc3339();
    sb[2] = this.dt.timeOfDay.rfc3339();
    sb[3] = this.tz.rfc3339;
    return sb.join("");
  }

  durationSince(before: DateTime<any>): TimeUnit {
    const mseDiff = this.mse - before.mse;
    return TimeUnit.fromMs(mseDiff as Ms);
  }

  compare<TzOther extends string>(other: DateTime<TzOther>): number {
    return this.compareSame(other.toTz(this.tz));
  }

  compareSame(other: DateTime<TzType>): number {
    const dseDiff = this.dt.ymd.dse - other.dt.ymd.dse;
    if (dseDiff != 0) return dseDiff;

    const msDiff = this.dt.timeOfDay.asMs - other.dt.timeOfDay.asMs;
    // console.log({ msDiff }, this.dt.timeOfDay.asMs, other.dt.timeOfDay.asMs);
    // console.log(this.rfc3339(), other.rfc3339());
    if (Math.abs(msDiff) <= 1) {
      return 0;
    }
    return msDiff;
  }

  get mse(): MsSinceEpoch {
    return this.toUtc().dt.mse;
  }

  get dse(): DaysSinceEpoch {
    return this.toUtc().dt.dse;
  }

  asTz<Tz extends string>(offset: LogicalTimezone<Tz>): DateTime<Tz> {
    return new DateTime(this.dt, offset);
  }

  toTz<Tz extends string>(tz: LogicalTimezone<Tz>): DateTime<Tz> {
    return new DateTime(
      this.dt.add(this.tz.info.offset.sub(tz.info.offset)),
      tz
    );
  }

  toUtc(): DateTime<Utc> {
    return this.toTz(Utc);
  }

  static fromRfc3339(rfc3339: string): Result<DateTime<NamelessFixedTimezone>> {
    const match = rfc3339.match(RFC3339_REGEX);
    if (!match) return Error("regex-fail");

    const [_, year, month, day, hour, minute, second, __, timezone] = match;

    const nd = NaiveDate.fromYmd1Str(year, month, day);
    if (Result.isErr(nd)) return nd as Error;

    const ndMaybeValid = Result.unwrap(nd).check();
    if (Result.isErr(ndMaybeValid)) return ndMaybeValid as Error;

    const ndValid = Result.unwrap(ndMaybeValid);

    const nt = NaiveTime.fromHmsStr(hour, minute, second);
    if (Result.isErr(nt)) return nt as Error;

    const dt = new DateTime(
      new NaiveDateTime(ndValid, Result.unwrap(nt)),
      LogicalTimezone.parse(timezone)
    );

    return dt;
  }

  get dow(): Weekday {
    return this.dt.ymd.dayOfWeek;
  }
}

export const rfc3339 = DateTime.fromRfc3339;
