export interface DateUnitLike {
  readonly yrs: number;
  readonly mths: number;
  readonly days: number;
  readonly wks: number;
}
export type DateUnitLikeOpt = Optional<DateUnitLike>;
export type DateUnitType = "years" | "months" | "days" | "weeks";

export class ScalarDateUnit<Unit extends DateUnitType>
  implements DateUnitLikeOpt
{
  readonly value: number;
  readonly type: Unit;

  get yrs(): Option<number> {
    return this.type == "years" ? this.value : null;
  }
  get mths(): Option<number> {
    return this.type == "months" ? this.value : null;
  }
  get days(): Option<number> {
    return this.type == "days" ? this.value : null;
  }
  get wks(): Option<number> {
    return this.type == "weeks" ? this.value : null;
  }

  constructor(value: number, type: Unit) {
    this.value = value;
    this.type = type;
  }

  static years(yrs: number): ScalarDateUnit<"years"> {
    return new ScalarDateUnit(yrs, "years");
  }

  static months(mths: number): ScalarDateUnit<"months"> {
    return new ScalarDateUnit(mths, "months");
  }

  static days(days: number): ScalarDateUnit<"days"> {
    return new ScalarDateUnit(days, "days");
  }

  static weeks(wks: number): ScalarDateUnit<"weeks"> {
    return new ScalarDateUnit(wks, "weeks");
  }
}

export class DateUnit implements DateUnitLike {
  readonly yrs: number;
  readonly mths: number;
  readonly days: number;
  readonly wks: number;

  constructor(du: DateUnitLikeOpt) {
    this.yrs = du.yrs ?? 0;
    this.mths = du.mths ?? 0;
    this.days = du.days ?? 0;
    this.wks = du.wks ?? 0;
  }
}
