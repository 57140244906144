import { uuidv4 } from "@lona/crypto";
import { StudyCard } from "./study-card";
import { replaceAll } from "@lona/polyfill";

export const MAP = replaceAll(
  /*         16->| */
  `
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
....................s............
.......................st........
........................st.......
.................................
................x................
..........t.........s............
...........t.....................
....................ts...........
.............f......t............
..............f.......t..........
....................t............
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................
.................................

`.trim(),
  "\n",
  ""
);
window["MAP"] = MAP;

export const EXAMPLE: StudyCard.Prop[] = [
  {
    id: uuidv4(),
    title: "米饭",
    definition: "Cooked Rice",
    pronunciation: "mǐfàn",
    background: "#db9ca0",
    task: "Define the word",
    coord: {
      c: 0,
      r: 0,
    },
  },
  {
    id: uuidv4(),
    title: "谢谢",
    definition: "Thank you",
    pronunciation: "xièxie",
    background: "#adb88a",
    task: "Define the word",
    coord: {
      c: 0,
      r: 1,
    },
  },
  {
    id: uuidv4(),
    title: "喜欢",
    definition: "to like",
    pronunciation: "xǐhuan",
    background: "#d9c5bb",
    task: "Define the word",
    coord: {
      c: 1,
      r: 0,
    },
  },
];
