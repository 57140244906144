import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { App } from "../routes/index.root";

@component({
  name: "langilo-command-row",
})
export class CommandRow extends LonaWebComponent {
  constructor() {
    super();
  }

  static $styles: HTMLTemplateElement[] = [
    css`
      #root {
        position: relative;
        width: 100%;
        height: 40px;

        border-radius: 8px;
        align-items: center;
        justify-content: center;
        gap: 4px;
        overflow: hidden;
        background: #3f4552;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <std-row id=root></std-row>
  `;
}

// #root::before {
//   z-index: -1;
//   position: absolute;
//   content: "";
//   inset: 0;
//   #background: rgba(0, 0, 0, 0.06);
//   #backdrop-filter: blur(12px);
// }
