import { TzAny, DateTime, MsSinceEpoch, NaiveDateTime, TimeUnit } from "./mod";
import { NaiveDate } from "./naive-date";
import { Time } from "./time";
import { TimezoneRegion } from "./timezone-region";

export interface TimeRange {
  start: MsSinceEpoch;
  end: MsSinceEpoch;
}

export interface DateRange {
  start: DateTime<TzAny>;
  end: DateTime<TzAny>;
}

export class DateRegion {
  readonly nd: NaiveDate;
  readonly tz: TimezoneRegion;

  constructor(nd: NaiveDate, tz: TimezoneRegion) {
    this.nd = nd;
    this.tz = tz;
  }

  get timeRange(): TimeRange {
    const start = this.nd.mse;
    return {
      start,
      end: (start + Time.MS_PER_DAY) as MsSinceEpoch,
    };
  }

  get dateStart(): DateTime<TzAny> {
    return this.tz.datetimeResolved(
      new NaiveDateTime(NaiveDate.fromMse(this.timeRange.start))
    );
  }

  get dateEnd(): DateTime<TzAny> {
    return this.tz.datetimeResolved(
      new NaiveDateTime(NaiveDate.fromMse(this.timeRange.end))
    );
  }

  get dateRange(): DateRange {
    return {
      start: this.dateStart,
      end: this.dateEnd,
    };
  }

  get dayLength(): TimeUnit {
    return this.dateEnd.durationSince(this.dateStart);
  }

  toString(): string {
    return `${this.nd.rfc3339()} ${this.tz.fullname}`;
  }

  transitions(): TimezoneRegion.Transition[] {
    return this.tz.transitionsBetween(this.timeRange);
  }
}

export class DateTimeRegion {
  readonly ndt: NaiveDateTime;
  readonly tz: TimezoneRegion;

  constructor(ndt: NaiveDateTime, tz: TimezoneRegion) {
    this.ndt = ndt;
    this.tz = tz;
  }
}
