import { css } from "../component-styles";

export namespace Tooltip {
  export const mixin = `
    content: attr(tooltip);
    position: absolute;
    pointer-events: none;
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.75rem;
    width: max-content;
  `;

  export const style = /* css */ `
    :has(> std-tooltip):is(:hover, :focus-visible, :active) > std-tooltip {
      opacity: 1;
      transition-delay: 200ms;
    }

    std-tooltip {
      pointer-events: none;
      user-select: none;
      opacity: 0;

      height: fit-content;
      position: absolute;
      z-index: 1;
      inline-size: max-content;
      text-align: start;
      font-size: 0.775rem;
      font-weight: normal;
      line-height: initial;
      margin: 0;
      border-radius: 5px;
      color: white;
      padding: 4px 12px;

      background: #222;
      bottom: calc(100% + var(--std-tooltip-margin, 4px));
      transition: opacity 0.3s ease;
    }

    std-tooltip[position=right] {
      bottom: unset;
      left: calc(100% + var(--std-tooltip-margin, 4px));
      top: 0px;
    }

    std-tooltip[position=left-overlap] {
      bottom: unset;
      left: 0px;
      top: 0px;
    }

    std-tooltip[position=right-overlap] {
      bottom: unset;
      right: 0px;
      top: 0px;
    }

    std-tooltip[position=bottom] {
      bottom: 0px;
      top: calc(100% + var(--std-tooltip-margin, 4px));
    }

    std-tooltip[position=left] {
      bottom: 0px;
      right: calc(100% + var(--std-tooltip-margin, 4px))
    }
  `;

  export const $style = css(style);

  export const make = () => document.createElement("std-tooltip");
}
